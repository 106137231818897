import React from 'react'


const IndexPage = ( { data } ) => {

    return (
        <>
        </>
        // <Layout>
        //     <SiteMetadata title={ siteTitle} description={ siteDescription } />

        //     <div
        //       className='index'
        //       css={ stylez }
        //     >
        //         text
        //     </div>
        // </Layout>
    )
}

export default IndexPage
